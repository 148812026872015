.multi-range-splinter {
  border: 0;
  box-shadow: none;

  .thumb {
    &::before {
      border: 1px solid #666;
      box-shadow: none;
      margin: -6px -12px;
    }
  }

  .caption {
    display: none !important;
  }

  .bar-left,
  .bar-inner,
  .bar-right {
    border: 1px solid #DDD;
    box-shadow: none;
  }
}