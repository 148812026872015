.node circle {
  fill: #fff;
  stroke: #e60000;
  stroke-width: 3px;
}

.node text {
  cursor: pointer;
  position: relative;
  text-shadow: 0px 0px 8px white,  0px 0px 8px white,  0px 0px 8px white,  0px 0px 8px white;
}

.link {
  fill: none;
  stroke: #ffb6b6;
  stroke-width: 2px;
  pointer-events: none;
}

.node--svg {
  cursor: pointer;
  overflow: visible;
}

.node--group {
  opacity: .1;
}

.node--highlighted {
  opacity: 1;
}

.node--selected text,
.node--parent text {
  font-weight: 700;
}

.text--origin,
.text--intermediate {
  transform: translate(0, -25px);
}

.text--end {
  transform: translate(20px, 0);
  text-shadow: none !important;
}
