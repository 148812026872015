div.react-datepicker-wrapper {
  @apply block;
}

.react-datepicker .react-datepicker__day-name {
  @apply m-1.5 w-8;
}

.react-datepicker .react-datepicker__day {
  @apply rounded-full text-gray-800 text-xs h-9 leading-9 m-1 w-9;
}

.custom-datepicker--filtered .react-datepicker .react-datepicker__day {
  @apply rounded-full border border-solid border-primary text-gray-800 text-xs h-9 leading-9 m-1 w-9 relative;
}

.react-datepicker .react-datepicker__day:hover,
.react-datepicker .react-datepicker__day--keyboard-selected,
.react-datepicker .react-datepicker__day--selected,
.custom-datepicker--filtered .react-datepicker .react-datepicker__day:hover,
.custom-datepicker--filtered .react-datepicker .react-datepicker__day--keyboard-selected,
.custom-datepicker--filtered .react-datepicker .react-datepicker__day--selected {
  @apply bg-primary rounded-full text-white;
}

.react-datepicker .react-datepicker__day--selected {
  @apply font-extrabold;
}

.react-datepicker .react-datepicker__day--outside-month {
  @apply invisible;
}

.react-datepicker .react-datepicker__day--disabled,
.custom-datepicker--filtered .react-datepicker .react-datepicker__day--disabled {
  @apply bg-white border-none text-gray-800 opacity-30 pointer-events-none;
}

.react-datepicker__triangle::before,
.react-datepicker__triangle::after {
  @apply transform -translate-x-4;
}

.custom-datepicker--filtered .react-datepicker .react-datepicker__day--highlighted--featured::before {
  content: '';
  @apply rounded-full border border-dotted border-primary text-gray-800 text-xs absolute -left-1 -top-1 -bottom-1 -right-1;
}